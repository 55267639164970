// ESM Config
const OBM_1_Login = { code: "OBM_1_Login", name: "OBM Login" };
const OBM_2_Logout = { code: "OBM_2_Logout", name: "OBM Logout" };
const UIF_1_Login = { code: "UIF_1_Login", name: "Common UI Login" };
const UIF_2_Logout = { code: "UIF_2_Logout", name: "Common UI Logout" };
const BVD_1_Login = { code: "BVD_1_Login", name: "BVD Login" };
const BVD_2_Logout = { code: "BVD_2_Logout", name: "BVD Logout" };

export const serviceCollection = [
  [
    OBM_1_Login,
    OBM_2_Logout,
    UIF_1_Login,
    UIF_2_Logout,
    BVD_1_Login,
    BVD_2_Logout,
  ],
];

export const APPMODE = "OPSB";
export const FARM_ALIAS = [
  "advantageinc",
  "corp-it",
  "corp-it-qa",
  "ctti",
  "ctti-pre",
  "Demo_Girish",
  "metrolinx",
  "metrolinx-dev",
  "montest9",
  "ot",
  "ot-dev",
  "pmdemo",
  "techmahindra",
  "techmahindra-dev",
  "telefonica",
  "telefonica-dev"
];
export const MODE = ['OPSB'];
export const UNITLABEL = "instance";
export const region = "us-west-2";
export const mainPageTitle = "OpsBridge System Health";
export const portalPageTitle = "OpsBridge System Health";
export const farmGeoData = {};
export const farmDetails = [];

/*
export const FARM_ALIAS_NOM = ['Demo_Girish'];
const PerfT_1_Login = {code: "PerfT_1_Login", name:"Perf Troubleshooting Login"};
const PerfT_2_Add_Metrics = {code: "PerfT_2_Add_Metrics", name: "Access Perf Troubleshooting"};
const PerfT_3_Logout = {code: "PerfT_3_Logout", name: "Perf Troubleshooting Logout"};
const BVD_1_Login = {code: "BVD_1_Login", name: "BVD Login"};
const BVD_2_NodeHealth_Report = {code: "BVD_2_NodeHealth_Report", name: "BVD NodeHealth Report"};
const BVD_3_Logout = {code: "BVD_3_Logout", name: "BVD Logout"};

export const service_NOM = [
  [
    PerfT_1_Login,
    PerfT_2_Add_Metrics,
    PerfT_3_Logout,
    BVD_1_Login,
    BVD_2_NodeHealth_Report,
    BVD_3_Logout
  ]
];
*/// production url
export const OPS_DATA_URL = "https://t350lltazi.execute-api.us-west-2.amazonaws.com/prod";
export const MONITOR_DATA_URL = "https://ur8n3itvp7.execute-api.us-west-2.amazonaws.com/prod";
// production cognito
export const userPoolId = "us-west-2_Xmsp74HXn";
export const userPoolWebClientId = "3bdsdo6s566c5qeoje8l2jtou6";
// global config
export const okColor = "#1aac60";
export const warnColor = "#ffc002";
export const errorColor = "#e5004c";
export const nodataColor = "#bdbec0";
export const MAINTENANCE_STATUS = [{ label: 'NOT STARTED', color: nodataColor }, { label: 'IN PROGRESS', color: warnColor }, { label: 'COMPLETED', color: okColor }];
export const INCIDENT_STATUS = [{ label: 'UNRESOLVED', color: warnColor }, { label: 'RESOLVED', color: okColor }];
export const brandColor = "linear-gradient(270deg, #088CB2 0%, #146693 8.78%, #23346A 36.9%, #222E61 74.96%, #19224A 100%)";
export const DATE_PICKER_FORMAT = "YYYY-MM-DD HH:mm:ss zZ";

